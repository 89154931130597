/* eslint-disable consistent-return */
/* eslint-disable react-func/max-lines-per-function */
import { B2BRegistrationStep, EmailConfirmationErrors } from '../vars'
import { navigate } from 'gatsby'
import { trackCodeWrong } from '../../../components/amplitude/auth/trackCodeWrong'
import { useB2BRegistrationContext } from './useB2BRegistrationContext'
import { useConfirmaEmailOtpMutation } from '../query'
import { useErrorModalContext } from '../../RegistrationModals/hooks/useErrorProvider'

export const useEmailCodeStepSubmit = () => {
  const [confirmEmailOtpMutation] = useConfirmaEmailOtpMutation()
  const context = useB2BRegistrationContext()
  const errorContext = useErrorModalContext()

  return async (values) => {
    try {
      const response = await confirmEmailOtpMutation({
        variables: {
          otpCode: values.code
        }
      })

      if (response.data.confirmEmail?.code) {
        const code = response.data.confirmEmail.code
        if (code === EmailConfirmationErrors.DUPLICATE_EMAIL) {
          errorContext.onIdentityTakenError()
          return
        }
        if (code === EmailConfirmationErrors.COMPANY_EMAIL_DOMAIN_MISMATCH) {
          errorContext.onServerError()
          return
        }
        if (code === EmailConfirmationErrors.INVALID_OTP) {
          trackCodeWrong()
          return { code: response.data.confirmEmail.message }
        }
      } else {
        context.updateContext({
          step: B2BRegistrationStep.EMAIL_CODE,
          canBeRequestAgain: false,
          attempt: 1
        })
        localStorage.setItem('isAfterB2bRegistration', true)
        navigate('/wizard')
      }
    } catch (err) {
      errorContext.onServerError()
    }
  }
}
