import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as BackSVG } from './img/back.svg'
import { Button } from '../../atoms/Button'
import { WizardStepsRange } from '../../atoms/WizardStepsRange'
import { size } from '../../constants'

const Icon = styled(BackSVG)``

export const BackButton = styled(({ className, noShow, goBack }) => (
  <Button.Icon
    className={className}
    noShow={noShow}
    onClick={goBack}
    type="button"
  >
    <Icon />
  </Button.Icon>
))``

export const StepProgressWithBack = styled(
  ({ className, currentStep, totalSteps, goBack, noShow, isActive }) =>
    isActive ? (
      <div className={className}>
        <BackButton goBack={goBack} noShow={noShow} />
        <WizardStepsRange currentStep={currentStep} totalSteps={totalSteps} />
      </div>
    ) : null
)`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 54px;
  align-items: center;

  @media (max-width: ${size.md}) {
    gap: 62px;
  }

  @media (max-width: ${size.sm}) {
    gap: 32px;
  }

  @media (max-width: ${size.xs}) {
    gap: 16px;
  }

  ${({ noShow }) =>
    noShow &&
    css`
      ${Button.Icon} {
        visibility: hidden;
      }
    `}
`
