import { gql, useLazyQuery, useMutation } from '@apollo/client'

const SEND_REGISTRATION_REQUEST = gql`
  mutation sendRegistrationRequest($input: SendRegistrationRequestInput!) {
    sendRegistrationRequest(input: $input) {
      ... on AuthErrorResponse {
        code
        message
      }
      ... on SendRegistrationRequestResponseSuccess {
        token
        datetime
        bitrixLeadId
      }
    }
  }
`

const PROMOCODE_CHECK = gql`
  query checkPromocodeAffiliation($input: CheckPromocodeAffiliationRequest!) {
    checkPromocodeAffiliation(input: $input) {
      ... on CheckPromocodeAffiliationSuccessResponse {
        needsManualVerification
      }
      ... on CheckPromocodeAffiliationErrorResponse {
        code
        message
      }
    }
  }
`

const EMAIL_CONFIRMATION = gql`
  mutation requestEmailConfirmation($input: RequestEmailConfirmationInput!) {
    requestEmailConfirmation(input: $input) {
      ... on EmailConfirmationRequestErrorResponse {
        __typename
        code
        message
      }
      ... on RequestEmailConfirmationRequestSuccessResponse {
        __typename
        otpExpiresAt
      }
    }
  }
`

const CONFIRM_EMAIL_OTP = gql`
  mutation confirmEmail($otpCode: String!) {
    confirmEmail(otpCode: $otpCode) {
      code
      message
    }
  }
`

export const useSendRegistrationRequestMutation = (input) =>
  useMutation(SEND_REGISTRATION_REQUEST, input)

export const useEmailConfirmationMutation = (input) =>
  useMutation(EMAIL_CONFIRMATION, input)

export const useConfirmaEmailOtpMutation = (input) =>
  useMutation(CONFIRM_EMAIL_OTP, input)

export const useCheckPromocode = (input) =>
  useLazyQuery(PROMOCODE_CHECK, {
    fetchPolicy: 'network-only',
    ...input
  })
