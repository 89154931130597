import App from '../components/App'
import Header from '../components/Header'
import React from 'react'
import SEO from '../atoms/SEO'
import styled from 'styled-components'
import {
  B2BRegistrationForm,
  B2BRegistrationFormWithContext
} from '../organisms/B2BRegistrationForm'
import { Grid } from '../atoms/Grid'
import { size } from '../constants'

const Page = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 76px repeat(2, auto);
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: #e5ecf1;

  @media (max-width: ${size.xs}) {
    grid-template-rows: 56px repeat(2, auto);
  }
`

const PageGrid = styled(Grid)`
  width: 587px;
  margin-top: 48px;
  margin-bottom: 330px;

  @media (max-width: ${size.lg}) {
    margin-bottom: 89px;
  }

  @media (max-width: ${size.md}) {
    margin-bottom: 103px;
  }

  @media (max-width: ${size.sm}) {
    margin-top: 24px;
    margin-bottom: 160px;
    width: 100%;
  }
`

const B2BForm = () => (
  <App>
    <SEO title="Запись к психологу онлайн | YouTalk" />
    <Page>
      <Header />
      <PageGrid>
        <B2BRegistrationFormWithContext>
          <B2BRegistrationForm />
        </B2BRegistrationFormWithContext>
      </PageGrid>
    </Page>
  </App>
)

export default B2BForm
