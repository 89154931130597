import React from 'react'
import {
  B2BRegistrationContextProvider,
  useB2BRegistrationContext
} from './hooks/useB2BRegistrationContext'
import { B2BRegistrationStep } from './vars'
import { EmailCodeStep } from './EmailCodeStep'
import { IdentityStep } from './IdentityStep'
import { PhoneCodeStep } from './PhoneCodeStep'
import { useEmailCodeStepSubmit } from './hooks/useEmailCodeStepSubmit'
import { useIdentityStepSubmit } from './hooks/useIdentityStepSubmit'
import { usePhoneCodeStepSubmit } from './hooks/usePhoneCodeStepSubmit'

export const B2BRegistrationForm = () => {
  const context = useB2BRegistrationContext()
  const onIdentitySubmit = useIdentityStepSubmit()
  const onPhoneCodeSubmit = usePhoneCodeStepSubmit()
  const onEmailCodeSubmit = useEmailCodeStepSubmit()

  return (
    <>
      {context.formData.step === B2BRegistrationStep.IDENTITY && (
        <IdentityStep onIdentitySubmit={onIdentitySubmit} />
      )}
      {context.formData.step === B2BRegistrationStep.PHONE_CODE && (
        <PhoneCodeStep onCodeSubmit={onPhoneCodeSubmit} />
      )}
      {context.formData.step === B2BRegistrationStep.EMAIL_CODE && (
        <EmailCodeStep onCodeSubmit={onEmailCodeSubmit} />
      )}
    </>
  )
}

export const B2BRegistrationFormWithContext = ({ children, disabled }) =>
  disabled ? (
    children
  ) : (
    <B2BRegistrationContextProvider>{children}</B2BRegistrationContextProvider>
  )
