import { Gender } from '../../atoms/mappers/gqlEnums/gender'
import { HowFeeling } from '../../atoms/mappers/gqlEnums/howFeeling'
import { Language } from '../../atoms/mappers/gqlEnums/language'
import { RegistrationSource } from '../../atoms/mappers/gqlEnums/registrationSourse'
import { SourceID } from '../../atoms/mappers/wizard/enums/sourceId'
import { TherapyType } from '../../atoms/mappers/gqlEnums/therapyType'
import { array, boolean, date, number, object, string } from 'yup'

const positiveInteger = number().positive().integer()
const registrationSource = string().oneOf(Object.values(RegistrationSource))
const language = string().oneOf(Object.values(Language))
const therapyType = string().oneOf(Object.values(TherapyType))
const clientFeelings = string().oneOf(Object.values(HowFeeling))
const gender = string().oneOf(Object.values(Gender))
const sourceID = string().oneOf(Object.values(SourceID))

const pricesFilter = object({
  from: number().integer(),
  to: positiveInteger
})

const psychologistPreference = object({
  gender: gender,
  ageMin: positiveInteger,
  ageMax: positiveInteger,
  lgbtq: boolean()
})

const userRegistrationPreferences = object({
  sessionDateTime: date(),
  clientFeelings: clientFeelings,
  language: language,
  clientType: therapyType,
  workAreas: array().of(string()),
  workApproaches: array().of(string()),
  psychologistPreference,
  psychologistID: string(),
  aboutHelp: string(),
  aboutPsychologist: string(),
  prices: array().of(pricesFilter)
})

const analyticsData = object({
  sourceId: sourceID.required()
})

export const sendRegistrationRequestInputSchema = object({
  phone: positiveInteger.required(),
  name: string().required(),
  age: positiveInteger.required(),
  registrationSource: registrationSource.required(),
  email: string().required(),
  timezone: string(),
  promocodeId: string(),
  userRegistrationPreferences: userRegistrationPreferences,
  analyticsData: analyticsData.required()
})
