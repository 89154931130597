import React from 'react'
import styled from 'styled-components'

export const WizardStepsRange = styled(({ className }) => (
  <div className={className}></div>
))`
  width: 100%;
  background: #f3f7fe;
  border-radius: 8px;
  height: 4px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    background: #03b2a5;
    border-radius: 8px;
    width: ${({ currentStep, totalSteps }) => {
      if (currentStep === 0) {
        return '4px'
      }
      return `${((currentStep || 0) * 100) / totalSteps}%`
    }};
    transition: width 0.3s ease-in;
  }
`
