/* eslint-disable max-len */
/* eslint-disable react/jsx-handler-names */
import React, { useCallback } from 'react'
import createDecorator from 'final-form-focus'
import styled from 'styled-components'
import { B2BRegistrationStep, subscription } from '../vars'
import { BackButton } from '../../../molecules/StepProgressWithBack'
import { ButtonAsLink } from '../../../../youtalk-storybook/src/ui'
import { CodeField } from '../../RegistrationModals/CodeField'
import { Form as FinalForm, useField } from 'react-final-form'
import {
  FormBody,
  FormHeader,
  FormItems,
  FormMain,
  FormWrapWithoutButton,
  ModalButtonGroup,
  NoSmsText,
  Paragraph,
  Title
} from '../../RegistrationModals/index.styles'
import { FormButton } from '../../RegistrationModals/FormButton'
import { RequestCodeAgain } from '../../RegistrationModals/RequestCodeAgain'
import { clearSubmitError, submit } from '../../../atoms/useFormValues'
import { trackCodeSendAgain } from '../../../components/amplitude/auth/trackCodeSendAgain'
import { useB2BRegistrationContext } from '../hooks/useB2BRegistrationContext'
import { useEmailConfirmationMutation } from '../query'
import { useErrorModalContext } from '../../RegistrationModals/hooks/useErrorProvider'

const BackButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`

const StyledBackButton = styled(BackButton)`
  padding: 0;
`

const focusOnError = createDecorator()

const Form = styled(({ className, handleSubmit, restart, errors }) => {
  const context = useB2BRegistrationContext()
  const [emailConfirmationMutation] = useEmailConfirmationMutation()

  const requestCode = useCallback(async () => {
    trackCodeSendAgain()
    context.updateContext({ loading: true })
    const result = await emailConfirmationMutation({
      variables: {
        input: {
          email: context.formData.email,
          promocodeName: context.formData.promocode
        }
      }
    })
    context.updateContext({
      loading: false,
      attempt: context.formData.attempt + 1,
      token: result,
      canBeRequestAgain: false,
      datetime: new Date()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.formData])

  const { stepsProps } = context
  const errorContext = useErrorModalContext()
  const {
    input: { value: code }
  } = useField('code')

  return (
    <FormMain
      className={className}
      onSubmit={submit({
        handleSubmit,
        restart,
        errors
      })}
    >
      <BackButtonWrapper>
        <StyledBackButton
          goBack={() =>
            context.updateContext({ step: B2BRegistrationStep.PHONE_CODE })
          }
          noShow={false}
        />
      </BackButtonWrapper>
      <FormWrapWithoutButton>
        <FormHeader>
          <Title bold>Введите код из рабочей почты</Title>
          <Paragraph>
            Это нужно для верификации вашего аккаунта. Отправили код на
            <br />
            {context.formData.email}{' '}
            <ButtonAsLink
              onClick={stepsProps[B2BRegistrationStep.EMAIL_CODE].onChangeEmail}
              type="button"
            >
              Изменить
            </ButtonAsLink>
          </Paragraph>
        </FormHeader>
        <FormBody>
          <FormItems>
            <CodeField />
          </FormItems>
          <RequestCodeAgain context={context} requestCode={requestCode} />
          <NoSmsText>
            <button onClick={errorContext.showMessengersPopup} type="button">
              Напишите нам
            </button>
            , если не приходит код
          </NoSmsText>
        </FormBody>
      </FormWrapWithoutButton>
      <ModalButtonGroup>
        <FormButton disabled={!code || code.length !== 4}>
          Продолжить
        </FormButton>
      </ModalButtonGroup>
    </FormMain>
  )
})`
  ${ButtonAsLink} {
    width: fit-content;
  }
`

export const EmailCodeStepForm = ({ onSubmit }) => (
  <FinalForm
    decorators={[focusOnError]}
    mutators={{ clearSubmitError }}
    onSubmit={onSubmit}
    render={({ handleSubmit, form: { restart }, errors }) => (
      <Form errors={errors} handleSubmit={handleSubmit} restart={restart} />
    )}
    subscription={subscription}
  />
)
