/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useCallback, useContext, useEffect } from 'react'
import dayjs from 'dayjs'
import { B2BRegistrationStep, LOGIN_RESEND_TIME } from '../vars'
import { ErrorContextProvider } from '../../RegistrationModals/hooks/useErrorProvider'
import { noop } from '../../../atoms/noop'
import { useRegistrationFormStorage } from '../../RegistrationModals/hooks/useRegistrationFormStorage'

const B2BRegistrationContext = createContext({})
const init = {
  step: B2BRegistrationStep.IDENTITY,
  canBeRequestAgain: false,
  attempt: 0
}

export const B2BRegistrationContextProvider = ({ children }) => {
  const [formData, setFormData] = useRegistrationFormStorage(init)

  const updateContext = useCallback(
    (values) => {
      setFormData({ ...formData, ...values })
    },
    [formData]
  )
  const resetContext = useCallback(() => {
    setFormData(init)
  }, [])

  useEffect(() => {
    if (
      dayjs().isAfter(
        dayjs(formData.datetime).add(LOGIN_RESEND_TIME, 'seconds')
      )
    ) {
      resetContext()
    }
  }, [])

  const stepsProps = {
    [B2BRegistrationStep.IDENTITY]: {
      onBack: noop
    },
    [B2BRegistrationStep.PHONE_CODE]: {
      onBack: noop,
      onChangePhone: () => updateContext({ step: B2BRegistrationStep.IDENTITY })
    },
    [B2BRegistrationStep.EMAIL_CODE]: {
      onBack: noop,
      onChangeEmail: () => updateContext({ step: B2BRegistrationStep.IDENTITY })
    }
  }

  return (
    <B2BRegistrationContext.Provider
      value={{
        formData,
        resetContext,
        updateContext,
        stepsProps
      }}
    >
      <ErrorContextProvider
        allFieldsState={{
          ...formData
        }}
      >
        {children}
      </ErrorContextProvider>
    </B2BRegistrationContext.Provider>
  )
}

export const useB2BRegistrationContext = () =>
  useContext(B2BRegistrationContext)
